@import "../../styles/variables";
.hero-banner {
    font-family: "Open Sans";
    position: relative;
    height: spacer(52.625);
    top: spacer(9);
    background: #113591;
    .text-area {
        .heading {
            margin: 0;
            padding-top: 9%;
        }
    }
    .hero-banner-text {}
    a:focus {
        outline: none;
        box-shadow: none;
    }
    .ds-c-button,
    .ds-c-button:hover,
    .ds-c-button:focus {
        background: transparent !important;
        color: $color-white;
        border: 1px solid $color-white;
        border-radius: 3px;
    }
    .home-download-button {
        font-weight: 700;
    }
    .right-download-arrow {
        position: relative;
    }
}

.hero-banner-var-1 {
    font-family: "Open Sans";
    position: relative;
    height: spacer(35.625);
    top: spacer(4);
    background: #113591;
    clear: both;
    .text-area-var-1 {
        font-family: "Open Sans";
        overflow-wrap: initial;
        width: 100%;
        clear: both;
        .headings-var-1 {
            padding-top: 5%;
            position: relative;
            white-space: normal;
            margin: 0 0 0 10%;
        }
        .hero-banner-text-var-1 {
            font-family: "Open Sans";
            padding-left: 10%;
            position: relative;
            width: 40%;
            margin: 0;
        }
    }
    .text-date-area-var-1 {
        margin-top: -120px;
        position: relative;
        max-width: fit-content;
        left: 60%;
        bottom: 30%;
        clear: both;
        .banner-date-text-var-1 {
            font-weight: 500;
            margin-bottom: 0;
            max-width: auto;
        }
        .banner-date-var-1 {
            top: -8px;
            font-weight: 700;
            margin: 0;
        }
    }
}

.hero-banner-var-2 {
    font-family: "Open Sans";
    position: relative;
    width: 100%;
    height: 231px;
    top: spacer(0);
    background: #113591;
    .text-area-var-2 {
        font-family: "Open Sans";
        left: spacer(13);
        .heading-var-2 {
            font-family: "Open Sans";
            margin-left: 180px;
            padding-top: 5%;
        }
    }
}

.hero-banner-var-3 {
    font-family: "Open Sans";
    position: relative;
    width: 100%;
    height: 231px;
    top: spacer(0);
    background: #113591;
    .text-area-var-3 {
        font-family: "Open Sans";
        left: spacer(13);
        width: 100%;
        .heading-var-3 {
            font-family: "Open Sans";
            margin-left: 180px;
            padding-top: 80px;
        }
    }
    .hero-banner-text-var-3 {
        font-family: "Open Sans";
        width: 100%;
        width: spacer(102.875);
        margin: 0;
    }
}