@import './src/styles/variables';

.text-block{

  .body-heading-hr {
    display: inline-block;
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, #01397B 0%, #037CCD 100%) 1;
  }

  .body-section-hr {
    border-bottom: 1px solid rgba(209, 209, 209, 1);

    padding-bottom: $spacer-3;
    padding-top: $spacer-1;
  }
}