@import '../../../../styles/variables.scss';
.help-drawer-toggle {
    background: transparent !important;
    margin-left: -$spacer-3;
}

.help-drawer {
    max-width: 75%;
    .ds-c-dialog__header {
        margin-top: -32px;
        margin-left: -32px;
        margin-right: -32px;
    }
    .ds-c-button,
    .ds-c-button:hover,
    .ds-c-button:active,
    .ds-c-button:visited,
    .ds-c-button:focus {
        margin-right: 16px;
        margin-top: 16px;
        background: transparent;
        border-radius: 3px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        .close-text {
            color: $color-white;
            text-decoration: none;
        }
    }
}

.help-drawer-header {
    background: #113591;
    background-size: cover;
    p:focus-visible {
        line-height: 1.5em;
    }
}

.help-drawer-header-text {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}