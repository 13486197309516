@use "sass:math";
$font-path: '~@cmsgov/design-system/dist/fonts';
$image-path: '~@cmsgov/design-system/dist/images';
$height-header: 64px;
$height-header-lg: 86px;
$height-footer: 96px;
$color-black: #000;
$color-black-alt: #212121;
$color-gray: #5b616b;
$color-gray-light: #d2d2d2;
$color-white: #fff;
$color-background: #f1f1f1;
$color-primary: #0071BC;
$color-primary-darker: #205493;
$color-blue-alt-light: #9BDAF1;
$color-blue-alt-lightest: #E1F3F8;
$color-blue-alt-dark: #00A6D2;
$color-blue-dark: #112e51;
$color-danger: #981b1e;
$color-placeholder: #455267;
$color-blue-text: #0872B9;
$color-banner-background-white: #FBF9E7;
// Design system override for error messages/borders
$color-error: #981b1e;
$color-error-cell: #f9dede;
$width-sm: 544px;
$width-md: 768px;
$width-lg: 1024px;
$width-xl: 1280px;
$width-xxl: 1680px;
// Design system override for ds-text, ds-h5, etc.
$base-font-size: 16px;
// Design system font sizes
$font-size-xl: 21px;
// Design system override for grid-gutter
$grid-gutter-width: 16px;
$button-font-size: 16px;
$small-button-font-size: 14px;
$large-button-font-size: 32px;
$footer-button-font-size: 14px;
$footer-font-size: 11px;
$nav-font-size: 16px;
$alert-font-size: 16px;
$spacer-1: 8px;
$spacer-2: $spacer-1 * 2;
$spacer-3: $spacer-1 * 3;
$spacer-4: $spacer-1 * 4;
$spacer-5: $spacer-1 * 5;
$spacer-6: $spacer-1 * 6;
$spacer-7: $spacer-1 * 7;
$spacer-half: math.div($spacer-1, 2);
@function spacer($factor) {
    @return $factor * 8px;
}

%input-box-shadow {
    box-shadow: 0 0 6px 1px #4fa5d5;
}

@import 'info-button';