@import "../../styles/variables.scss";
.modal {
    max-width: 25%;
    .dialog-header {
        clip: rect(0 0 0 0);
        height: 50px;
        margin: -20px 0 0 0;
        border: 0;
    }
    .ds-c-dialog__close,
    .ds-c-dialog__close:hover,
    .ds-c-dialog__close:active,
    .ds-c-dialog__close:visited,
    .ds-c-dialog__close:focus {
        background: transparent;
        border-radius: 3px;
        border: 1px solid #0000ff;
        box-sizing: border-box;
        text-decoration: none !important;
        padding: 0 5px !important;
        .close-text {
            color: $color-blue-dark;
            text-decoration: none !important;
        }
    }
}