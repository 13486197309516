@import "../../styles/variables";
.header {
    font-family: "Open Sans";
    align-items: baseline;
    .header-link-container {
        margin: 0 32px;
        line-height: 25px;
        text-decoration: none;
        font-size: 24px;
    }
    .header-nav-links {
        line-height: 21px;
        text-decoration-line: underline;
        color: $color-blue-text;
        word-wrap: break-word;
        margin-top: -1%;
        a:hover,
        a:active,
        a:visited {
            color: $color-blue-text;
        }
    }
    a:focus {
        text-decoration: none !important;
        box-shadow: 0 0 0 3px #fff, 0 0 4px 6px #bd13b8 !important;
        outline: 3px solid transparent !important;
        outline-offset: 3px !important;
    }
    .header-nav-button {
        background: $color-primary;
        border-radius: 3px;
        color: $color-white;
    }
}