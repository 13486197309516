// Paths to images and fonts relative from the SCSS entry point
$font-path: '~@cmsgov/design-system/dist/fonts';
$image-path: '~@cmsgov/design-system/dist/images';
// Core CMSDS styles
@import './variables';
@import '~@cmsgov/design-system/dist/scss/index';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.root {
    min-height: calc(100vh);
}

body {
    background: $color-white;
    margin: 0;
}

td {
    margin: 0;
    padding: 0;
}

.ds-c-label,
.ds-c-field,
.ds-h5 {
    line-height: 1.3;
}

.ds-c-dialog {
    overflow: none;
}

.ds-title {
    color: $color-base;
    font-weight: 300;
    line-height: unset;
}

.ds-h5 {
    margin: $spacer-3;
    max-height: 1.25em;
}

.navlink {
    font-size: $nav-font-size;
    white-space: nowrap;
}

.form-alert-banner {
    margin-bottom: -1 * $spacer-2;
    margin-top: $spacer-2;
    padding-left: 0;
    padding-right: 0;
}

.full-width {
    width: 100%;
}

.disabled-panel {
    opacity: 0.5;
}

.disabled-text {
    color: $color-gray;
}

.help-button {
    color: $color-primary;
    font-size: $base-font-size;
    padding-left: 2px;
    padding-right: 0;
    text-decoration: none;
    vertical-align: middle;
}

.ds-c-vertical-nav__label {
    font-size: $nav-font-size;
    &:focus,
    &:focus:visited,
    &--current,
    &--current:focus,
    &--current:visited,
    &--current:focus:visited {
        border-left-color: $color-primary;
        color: $color-primary;
        font-weight: 700;
        outline: 0;
    }
}

.ds-c-vertical-nav__subnav {
    .ds-c-vertical-nav__label,
    .ds-c-vertical-nav__label.ds-c-vertical-nav__label--current {
        border: 0;
    }
}

a,
.ds-c-link {
    color: $color-primary;
    &:focus,
    &:focus:visited {
        // background-color: unset;
        // -webkit-box-shadow: unset;
        // box-shadow: unset;
        // color: #4c2c92;
        // text-decoration: unset;
    }
}

.intro-modal-action {
    margin: $spacer-1;
    &:first-child {
        margin-left: 0;
    }
    &--secondary:focus,
    &--secondary:visited:focus {
        @extend %input-box-shadow;
        background-color: $color-white;
        border-color: $color-blue-dark;
        color: $color-blue-dark;
    }
    .ds-c-dialog__actions {
        align-content: center;
        margin-top: 0;
    }
}

.bold {
    font-weight: bold;
}

.intro-modal,
.hide-modal-close {
    .ds-c-dialog__close {
        visibility: hidden;
    }
}

.ds-c-button.ds-c-dialog__close svg {
    display: none
}

svg .cls-1,
svg .cls-2 {
    fill: $color-white;
    stroke: none;
    stroke-miterlimit: none;
}

.ds-c-alert {
    font-size: $alert-font-size;
    svg {
        float: left;
        height: 40px;
        margin-left: -48px;
        margin-top: -8px;
        width: 40px;
    }
    a,
    button {
        color: $color-black-alt;
        &:active,
        &:focus,
        &:focus:visited {
            background-color: transparent;
            color: $color-primary;
        }
    }
}

.ds-c-alert-red {
    input {
        border: 3px solid #d10f0f !important;
    }
}

@media (max-width: 99px) {
    .modal {
        height: 100%;
        max-width: 100%;
        width: 100%;
    }
    .ds-c-dialog,
    .ds-c-dialog-wrap {
        background-color: $color-white;
        box-shadow: unset;
        -webkit-box-shadow: unset;
        padding: $spacer-2;
    }
    .ds-c-dialog-wrap {
        padding: 0;
    }
}

.privacy-table {
    margin-top: $spacer-1;
    tr:first-child td {
        border-top: 0;
    }
    th {
        width: 70%;
        &:first-child,
        &:last-child {
            width: 15%;
        }
        &:last-child {
            text-align: right;
        }
    }
    td {
        border-top: $spacer-1 transparent solid;
        &:first-child,
        &:last-child {
            vertical-align: top;
        }
        &:last-child {
            float: right;
        }
        label:not(:last-child) {
            margin-top: 0;
        }
    }
}

.privacy-modal {
    h2,
    p {
        margin-bottom: 0;
    }
    p {
        margin-top: $spacer-2;
    }
}

.content-frame {
    background: $color-white;
    border-radius: 3px;
    margin-top: $spacer-2;
}

@media (min-width: $width-md) {
    .content-frame {
        &--left {
            margin-right: $spacer-1;
        }
        &--right {
            margin-left: $spacer-1;
        }
    }
}

@media (max-width: $width-lg) {
    .disabled-panel,
    .title-panel--disabled {
        display: none;
    }
}

@media (max-width: 960px) {
    .intro-modal-action {
        margin-left: 0;
        margin-right: 0;
        width: 80%;
    }
}

@media (max-width: 960px) {
    .ds-c-dialog__actions {
        display: block;
        text-align: center;
    }
}

@media (min-width: $width-lg) {
    .modal {
        max-width: 55%;
    }
}

@media (min-width: $width-xxl) {
    .modal {
        max-width: 50%;
    }
}