@import '../../styles/variables';
.ds-c-label {
    font-weight: bold;
    margin: 0;
}

.ds-c-field:focus {
    @extend %input-box-shadow;
    outline: 0;
}

.input-field {
    margin: $spacer-1 0 0 0;
}

.dynamic-field-list {
    margin: $spacer-1 0;
}

.dynamic-field {
    display: flex;
    flex-wrap: nowrap;
    .input-field {
        margin: 0 $spacer-half 0 0;
    }
    .delete-button {
        @extend %info-button;
        content: unset;
        font-size: $base-font-size;
        padding: 0;
        text-decoration: none;
    }
    .sublabel {
        margin: auto 8px auto 0;
    }
}

.add-button {
    border: 0;
    font-size: $base-font-size;
    font-weight: 400;
    margin: 1px 0 0;
    padding: 0;
    span {
        font-size: $base-font-size;
        margin-right: 6px;
        vertical-align: text-top;
    }
}