@import './src/styles/variables';
.download-body {
    position: relative;
    margin: 8em auto;
    max-width: 64.29em;
    width: 100%;
    overflow-wrap: break-word;
    resize: both;
    .psf-type-text {
        max-width: 965px;
        line-height: 24px;
        color: #5B616B;
        overflow-wrap: inherit;
    }
    .ds-h3 {
        width: fit-content;
    }
    .ds-h3:focus {
        box-shadow: 0 0 0 3px #fff, 0 0 4px 6px #bd13b8;
        outline: 3px solid transparent;
        outline-offset: 3px;
    }
    .ds-c-alert__text {
        overflow-wrap: break-word;
        color: #d10f0f;
    }
    .ds-c-alert {
        max-width: 80% !important;
    }
    .ds-c-fieldset.input-field {
        >.ds-c-label {
            div {
                font-size: $font-size-xl;
            }
        }
        >div {
            display: inline-block;
            min-width: 8.125em;
            .ds-c-choice+label {
                line-height: 1.3125em;
            }
        }
    }
    .filters {
        .filter-options {
            line-height: 32px;
            display: inline;
            max-width: 80%;
            padding: 14px 0 14px 0;
            .filter-header {
                font-weight: 700;
                margin-top: 10px;
                font-size: 18px;
            }
            /* &.no-filters {
                // not removing the styling for future references
                font-style: italic;
                display: block;
                line-height: $spacer-2;
                margin: $spacer-1 0;
            } */
            .applied-filter {
                position: relative;
                border: 1px solid $color-primary-darker;
                color: $color-primary-darker;
                background: transparent;
                border-radius: 4px;
                max-width: 250px;
                text-align: center;
                width: 100%;
                display: inline-block;
                font-weight: 700;
            }
            .add-filters {
                color: $color-white;
            }
        }
        .filter-options:focus-visible {
            outline: solid 2px;
        }
        .filter-by {
            padding-bottom: 10px;
            .ds-c-label {
                font-weight: 700;
            }
            .ds-c-choice-wrapper {
                max-width: none;
            }
        }
    }
    .submit-buttons {
        padding: 16px 8px 24px 0;
        margin-top: 48px;
        left: 9em !important;
        .download-button {
            position: relative;
            float: right;
            right: 24px !important;
        }
    }
    .download-link {
        margin-top: 1.14em;
        position: relative;
        max-width: 75% !important;
        h5 {
            margin-bottom: 10px;
        }
        .ds-c-label {
            display: none;
        }
        input {
            border: 2px solid #8C8C8C;
            background: #FFFFFF !important;
            border-radius: 0.285em;
            padding: 5px 70px 5px 10px;
            height: 3.428em;
            width: 100%;
            max-width: 100% !important;
        }
        button {
            border: none;
            background: transparent;
            font-size: 25px;
            transition: .3s ease all;
            position: absolute;
            bottom: 4px;
            right: 0;
            color: #0071bc;
            &:hover,
            &:focus {
                color: #085399;
            }
        }
    }
}