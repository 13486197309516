@import "../../../styles/variables";
.doc-body {
    position: relative;
    margin-top: 8em;
    background: #FAFAFA;
    .body-heading-hr {
        display: inline-block;
        border-bottom: 2px solid;
        border-image: linear-gradient(90deg, #01397B 0%, #037CCD 100%) 1;
    }
    .body-section-hr {
        padding-bottom: $spacer-3;
        padding-top: $spacer-1;
    }
    .active-data-dictionary {
        display: inline-block;
        border-bottom: 2px solid;
        border-image: linear-gradient(90deg, #01397B 0%, #037CCD 100%) 1;
    }
    .ds-c-button--transparent,
    .ds-c-button--transparent:active,
    .ds-c-button--transparent:visited,
    .ds-c-button--transparent:focus {
        background: none !important;
        color: #212121;
        text-decoration: none;
    }
    .inactive-data-dictionary {
        color: #5B616B;
    }
    .data-dictionary-entries {
        width: 772px;
        background: $color-white;
        overflow: hidden !important;
    }
    .doc-change-log {
        background: $color-white;
        width: 100%;
        .change-log-section-hr {
            border-bottom: 1px solid #D7D7D7;
            width: 100;
        }
    }
    .show-more-less {
        color: #0071bc;
    }
}