%info-button {
  color: $color-primary;
  content: 'info';
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons', serif;
  margin-bottom: auto;
  margin-left: $spacer-half;
  margin-top: auto;
  max-height: 20px;
  vertical-align: top;

  &:focus,
  &:focus:visited {
    background-color: transparent;
    outline: $color-primary solid 1px;
  }
}
