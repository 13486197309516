@import "../../styles/variables";
.banner {
    position: absolute;
    width: 100%;
    height: auto;
    top: 132px;
    z-index: 1;
    background: $color-banner-background-white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    .banner-body {
        .banner-text {
            margin: -8px 8px;
        }
        .closeButton {
            background: transparent;
        }
    }
}