.footer {
    font-family: "Open Sans";
    background: #FFFFFF;
    background-clip: content-box;
    border-bottom: 56px #FAFAFA !important;
    .footer-line {
        height: 2px;
        background: linear-gradient(90deg, #023A7C 0%, #0577C5 100%);
    }
    a,
    a:hover,
    a:active,
    a:visited {
        //text-decoration: none;
        color: #000000;
    }
    .footer-container {
        background: #FFFFFF;
        overflow-x: hidden;
        overflow-y: hidden;
        .focus:focus-visible {
            outline-offset: 0.7em;
        }
        .footer-margin {
            margin-top: 1px;
        }
    }
    .logo-div {
        margin-top: 8px;
    }
    .ds-h4 {
        line-height: inherit;
    }
}