.skip-link {
    margin-right: 1rem;
    position: absolute;
    transform: translateX(-200%);
    transition: transform 0.1s;
    background-color: #0071BC;
    border-color: transparent;
    color: #fff;
    z-index: 999;
    font-size: 15px;
    padding: 4px;
}

.skip-link:focus {
    position: absolute;
    transform: translateX(0);
    font-size: 15px;
    padding: 4px;
}