@import '../../styles/variables.scss';
.text-center {
    text-align: center;
}

.alert-box-size {
    max-width: 50ex !important;
    margin-top: 260px;
}

.alert-box {
    max-width: 5%;
    .ds-c-dialog__header {
        margin-top: -32px;
        margin-left: -32px;
        margin-right: -32px;
    }
    .ds-c-button,
    .ds-c-button:hover,
    .ds-c-button:active,
    .ds-c-button:visited,
    .ds-c-button:focus {
        margin-right: 16px;
        margin-top: 16px;
        background: transparent;
        border-radius: 3px;
        border: 0;
        box-sizing: border-box;
        .close-text {
            color: $color-white;
            text-decoration: none;
            font-weight: 700;
            font-size: 17px;
        }
    }
}

.alert-box-header {
    background: #113591;
    background-size: cover;
}

.header-text {
    padding: 0 24px !important;
    display: flex;
    align-items: center;
    margin-bottom: -24px;
}

.ds-c-dialog__close {
    margin-top: 30px !important;
}

.ds-c-icon--warning {
    font-size: 25px !important;
    margin-left: -16px;
    padding-right: 10px;
}

.hide {
    display: none!important;
}