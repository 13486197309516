@import './src/styles/variables';
@import url('https://fonts.googleapis.com/css?family=Montserrat');
.support-body {
    position: relative;
    margin-top: spacer(16);
  
  }


// .wrapper{
//   height: 100vh;
//   width: 200vh;
//   margin-right: 600px;
  
// }

// h1 {
//   text-align: center;
//   margin: 2rem 0;
//   font-size: 2.0rem;
// }

.accordion {
  width: 100%;
  max-width: 3000px;
  margin-left: 360px;
}
.accordion-item {
  background-color: #fff;
  color: +4;
  margin: 1rem 0;
  padding: 1px;
  border: 1px solid #D7D7D7;

}
.accordion-item-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  padding: 0.2rem 2rem 0.2rem 1rem;
  min-height: 1.0rem;
  line-height: 1.00rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
}
.accordion-item-header::after {
  font-size: 1rem;
  position: absolute;
  right: 1rem;
}

.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image:  1px solid #D7D7D7;
}

@media(max-width:767px) {
  html {
    font-size: 14px;
  }
}