@import './src/styles/variables';

.top-of-page-button {
  position: absolute;
  right: spacer(87);
  bottom: 0;

  .ds-c-button {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    background: transparent;
  }

  .arrow-down {
    position: absolute;
    right: spacer(5.375);
    top: $spacer-5;
  }
}