@import '../../../../styles/variables.scss';
.filter-model {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    .dropdown {
        .ds-c-field {
            max-width: 28em;
        }
    }
    input.ds-c-field::placeholder {
        color: $color-placeholder !important;
    }
    input.ds-c-field:-ms-input-placeholder {
        color: $color-placeholder !important;
    }
    input.ds-c-field::-ms-input-placeholder {
        color: $color-placeholder !important;
    }
    input.ds-c-field,
    select.ds-c-field {
        border: 1px solid #212121;
        box-sizing: border-box;
        border-radius: 3px;
    }
    .close-filter-button {
        //position: absolute;
        //bottom: 96px;
        color: $color-black !important;
        border-color: $color-black !important;
        border-radius: 3px !important;
    }
    .filter-sub-options {
        line-height: $spacer-4;
        display: inline;
        &.no-sub-filters {
            font-style: italic;
            display: block;
            line-height: $spacer-2;
            margin: $spacer-1 0;
        }
        .applied-sub-filter {
            position: relative;
            border: 0.0625em solid $color-primary-darker;
            color: $color-primary-darker;
            background: $color-white;
            border-radius: 0.25em;
            max-width: 8em;
            text-align: center;
            width: 100%;
            display: inline-block;
            //margin-right: 16px;
            font-weight: 700;
            //margin-top: 16px;
        }
    }
    .visuallyhidden {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
    .ds-c-button--success {
        background-color: #0071BC;
        border-color: transparent;
        color: #fff;
        &:hover {
            background-color: #085399;
        }
        &:focus,
        &:target {
            box-shadow: 0 0 0 3px #ffffff, 0 0 4px 6px #bd13b8;
            outline: 3px px solid transparent;
            outline-offset: 3px;
            background-color: #0071BC !important;
        }
    }
}

.ds-c-single-input-date-field .rdp {
    background: white;
    z-index: 1;
}

.ds-c-single-input-date-field__field-wrapper .ds-c-field {
    max-width: 12em !important;
}