
.change-log-entry {

.ds-l-container{
  overflow:inherit
}

  .change-log-arrow-button {
    position: relative;
    left: 70px;
    padding-bottom: 30%;
  }

  .change-log-arrow-button-close {
    position: relative;
    left: 60px;
    bottom: 20px;
    text-align: center;
  }

  .change-log-arrow {
    margin-top: 30%;
    text-align: center;
  }

  .change-log-arrow-close {
    transform: rotate(180deg);
    left: 70px;
    bottom: 20px;
  }
}