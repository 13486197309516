@import '../../styles/variables';
.side-nav {
  position: sticky;
  top: $spacer-3;

  a,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
    color: $color-black;
  }
}